<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">
        {{ partner.business_name && partner.business_name.length > 15 ? `${partner.business_name.slice(0,15)}...` :
        partner.business_name }}
    </h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <router-link :to="{'name': 'partner-edit', params: {'id' : partner.id}}" href="#" class="header-icon header-icon-2"
        v-if="hasPermission('partner_update_personal_detail')">
        <i class="far fa-edit"></i>
    </router-link>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-4"
        v-if="$store.state.user.user_type == 'super_admin'">
        <i class="fa fa-search"></i>
    </a>
    <button class="header-icon header-icon-3" @click="impersonate">
        <i class="fas fa-user primary-alt-base-text"></i>
    </button>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div v-if="partnerActivePage == 'partner_orders'">
        <div class="text-center" v-if="searchedResultShow">
            <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
                Search results for
                <span class="font-500 text-dark">'{{ search }}'</span>
                <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                    style="padding: 3px 10px !important;">Clear</button>
            </p>
        </div>
    </div>
    <div class="d-flex justify-content-evenly mt-2">
        <div class="card rounded-s col-4"
            :class="[partnerActivePage == 'partner_profile' ? 'partnerPageActive' : 'shadow-l']">
            <button @click="partnerActivePage = 'partner_profile'" class="content m-0 px-2 py-3 text-center">
                <i class="fas fa-user mb-3 font-20 primary-light-text"></i>
                <h5 class="letterSpacing font-500">Profile</h5>
            </button>
        </div>
        <div class="card rounded-s col-4"
            :class="[partnerActivePage == 'partner_orders' ? 'partnerPageActive' : 'shadow-l']">
            <button @click="partnerActivePage = 'partner_orders'" class="content m-0 px-2 py-3 text-center">
                <i class="fas fa-shopping-bag mb-3 font-20 primary-light-text"></i>
                <h5 class="letterSpacing font-500">Orders</h5>
            </button>
        </div>
    </div>

    <!-- <div class="divider-icon divider-margins text-secondary mb-2">
    </div> -->

    <!------------- PARTNER PROFILE PART START ------------->

    <div v-if="partnerActivePage == 'partner_profile'">


        <!------------- PARTNER PERSONAL DETAIL PART START ------------->

        <div class="card card-style">
            <div class="content">
                <div class="d-flex flex-column justify-content-center align-items-center mb-4">
                    <div class="mb-3 p-approvedUser" v-if="partner.registration_approved == 'approved'">
                        <i class="fas fa-user-check p-approveredUserIcon"></i>
                    </div>
                    <div class="mb-3 p-disapprovedUser" v-if="partner.registration_approved == 'disapproved'">
                        <i class="fas fa-user-alt-slash fa-4x p-disapprovedUserIcon"></i>
                    </div>
                    <div class="mb-3 p-pendingUser" v-if="partner.registration_approved == 'pending'">
                        <i class="fas fa-user-clock fa-4x p-pendingUserIcon"></i>
                    </div>
                    <h2 class="p-personalDetail">
                        {{ partner.full_name ? partner.full_name : '-' }}
                    </h2>
                    <div class="p-detailInfo" v-if="partner.partner_type">
                        <h5 class="text-uppercase">( {{ partner.partner_type }} )</h5>
                    </div>
                </div>
                <div class="divider"></div>

                <!-- <div class="text-end ordersRoute">
                <router-link :to="{'name': 'partner-orders', params: {'id' : partner.id}}" href="#"
                    class="color-white primary-light-bg px-2 py-1 font-15 text-center rounded-circle letterSpacing "
                    v-if="hasPermission('partner_update_personal_detail')">
                    <i class="fas fa-shopping-bag"></i>
                </router-link>
            </div> -->


                <div class="p-businessDetails mb-3 mx-2">
                    <div class="p-detailIconContainer me-3">
                        <div class="p-detailIconWrapper">
                            <i class="fas fa-building p-businessIcon"></i>
                        </div>
                    </div>
                    <div class="p-detailInfo">
                        <h3 class="text-capitalize font-16 mb-2">{{ partner.business_name ? partner.business_name :'-'}}
                        </h3>
                        <div class="col-12 letterSpacing color-grey">
                            {{ partner.address ? partner.address : "" }} -
                            {{ partner.zipcode ? partner.zipcode : "" }}
                        </div>
                        <div class="col-12 letterSpacing color-grey">
                            {{ partner.city ? partner.city : "" }} -
                            {{ partner.state ? partner.state : "" }}&nbsp;
                            <span class="letterSpacing color-grey">
                                ( {{ partner.country_emoji ? partner.country_emoji : "" }}
                                {{ partner.country ? partner.country : "" }} )
                            </span>
                        </div>
                        <div class="col-12 letterSpacing color-grey">
                            {{ partner.business_website ? partner.business_website : "" }}
                        </div>
                    </div>
                </div>
                <div class="p-details mb-3 mx-2" v-if="partner.email">
                    <div class="p-detailIconContainer me-3">
                        <div class="p-detailIconWrapper">
                            <i class="fas fa-envelope fa-x p-detailIcon"></i>
                        </div>
                    </div>
                    <div class="p-detailInfo">
                        <h5>{{ partner.email.length >25 ? `${partner.email.slice(0,21)}...` : partner.email}}</h5>
                    </div>
                </div>
                <div class="p-details mb-3 mx-2">
                    <div class="p-detailIconContainer me-3">
                        <div class="p-detailIconWrapper">
                            <i class="fas fa-phone fa-x p-detailIcon"></i>
                        </div>
                    </div>
                    <div class="p-detailInfo">
                        <h5>{{ partner.phone ? partner.phone :'-'}}</h5>
                    </div>
                </div>
                <div class="p-details mb-3 mx-2">
                    <div class="p-detailIconContainer me-3">
                        <div class="p-detailIconWrapper">
                            <i class="fas fa-link fa-x p-detailIcon"></i>
                        </div>
                    </div>
                    <div class="p-detailInfo">
                        <h5 class="fw-bold">{{ partner.referral_code ? partner.referral_code :'-'}}</h5>
                    </div>
                </div>
                <div class="p-details mb-3 mx-2" v-if="partner.parent">
                    <div class="p-detailIconContainer me-3">
                        <div class="p-detailIconWrapper">
                            <i class="fas fa-user fa-x p-detailIcon"></i>
                        </div>
                    </div>
                    <div class="p-detailInfo">
                        <h5 class="fw-bold">
                            <router-link :to="{'name': 'partner-detail', params: {'id' : partner.parent.id}}" href="#">
                                {{ partner.parent && partner.parent.full_name ? partner.parent.full_name : "" }}
                            </router-link>
                        </h5>
                    </div>
                </div>
                <div class="p-details mx-2">
                    <div class="p-detailIconContainer me-3">
                        <div class="p-detailIconWrapper">
                            <i class="fas fa-clock fa-x p-detailIcon"></i>
                        </div>
                    </div>
                    <div class="p-detailInfo">
                        <h5>{{ localDateTime(partner.created_at) }}</h5>
                    </div>
                </div>
            </div>
        </div>

        <!------------- PARTNER PERSONAL DETAIL PART END ------------->

        <!------------- PARTNER BUSINESS & BANK DETAIL PART START ------------->

        <div v-for="(value, key) in partner.values">
            <div class="p-cardTitleContainer mb-3 mx-4" v-if="getLabel(key) === 'Business Detail'">
                <div class="p-businessDetailIconWrapper me-3">
                    <div class="p-IconContainer">
                        <i class="fas fa-briefcase p-bankDetailCardIcon"></i>
                    </div>
                </div>
                <h5 class="p-cardTitle mb-0">
                    {{ getLabel("business_detail")}}
                </h5>
            </div>
            <div class="p-cardTitleContainer mb-3 mx-4" v-if="getLabel(key) === 'Business KYC Details'">
                <div class="p-businessDetailIconWrapper me-3">
                    <div class="p-IconContainer">
                        <i class="fas fa-id-card p-bankDetailCardIcon"></i>
                    </div>
                </div>
                <h5 class="p-cardTitle mb-0">
                    {{ getLabel("business_kyc_details")}}
                </h5>
            </div>
            <div class="p-cardTitleContainer mb-3 mx-4" v-if="getLabel(key) === 'Business Bank Account Information'">
                <div class="p-businessDetailIconWrapper me-3">
                    <div class="p-IconContainer">
                        <i class="fas fa-landmark p-bankDetailCardIcon"></i>
                    </div>
                </div>
                <h5 class="p-cardTitle mb-0">
                    {{ getLabel("business_bank_account_information") }}
                </h5>
            </div>

            <div class="card card-style">
                <div class="content">
                    <div v-for="(fieldValue, fieldKey) in value" class="row mb-0">
                        <div>
                            <label class="text-dark letterSpacing font-14 font-600 ms-2 mb-2"
                                style="opacity: 1;background-color: transparent;">
                                {{ fieldKey ? getLabel(fieldKey) : "" }}
                            </label>
                            <a :href="fieldValue.value ? fieldValue.value : ''" target="_blank"
                                class="letterSpacing py-2 color-blue-dark form-control fs-6 font-500 mb-1"
                                style="border:none; border-radius: 30px !important;background-color: rgb(213, 229, 254);"
                                v-if="fieldValue.type == 'input_file'">
                                <i class="fas fa-file-alt me-2 letterSpacing"></i>&nbsp;View
                            </a>
                            <div v-else class="letterSpacing py-2 form-control fs-6 font-500 mb-2 text-secondary"
                                style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                                {{ fieldValue.value ? fieldValue.value : "" }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!------------- PARTNER BUSINESS & BANK DETAIL PART END ------------->
    </div>

    <!------------- PARTNER PROFILE PART END ------------->


    <!------------- PARTNER ORDERS PART START ------------->

    <div v-if="partnerActivePage == 'partner_orders'">
        <div v-for="record in orders" class="card card-style mb-2 mx-2 rounded-s">
            <div class="content my-2 mx-3">
                <router-link :to="{'name': 'order-detail', params: {'id' : record.id}}">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex flex-column justify-content-between">
                            <h2 class="font-15 font-400 line-height-s mt-1 mb-1 text-secondary letterSpacing">
                                {{ record.title }}
                                <span class="primary-alt-base-text">/</span>
                                <span class="font-13 ms-1 text-secondary">{{ localDate(record.date) }}</span>
                            </h2>
                            <div class="font-12 line-height-s mt-3 mb-1 letterSpacing">
                                <span class="orderStatusPill"
                                    v-if="record.status == 'Created' || record.status == 'Awaiting For Approval' || record.status == 'Processing Payment' || record.status == 'Processing Order'">
                                    <i class="fas fa-pen" v-if="record.status.toLowerCase() == 'created'"></i>
                                    <i class="fas fa-clock"
                                        v-if="record.status.toLowerCase() == 'awaiting for approval'"></i>
                                    <i class="fas fa-clock"
                                        v-if="record.status.toLowerCase() == 'awaiting for payment'"></i>
                                    <i class="fas fa-clock"
                                        v-if="record.status.toLowerCase() == 'processing payment'"></i>
                                    <i class="fas fa-hourglass-end"
                                        v-if="record.status.toLowerCase() == 'processing order'"></i>
                                    {{ record.status }}
                                </span>
                                <span class="orderStatusPill" v-if="record.status == 'Shipped'">
                                    <i class="fas fa-shipping-fast"></i>
                                    {{ record.status }}
                                </span>
                                <span class="orderStatusPill completeOrder" v-if="record.status == 'Delivered'">
                                    <i class="fas fa-check"></i>
                                    {{ record.status }}
                                </span>
                                <span class="orderStatusPill text-danger" style="border-color: rgb(220,52,68);"
                                    v-if="record.status && record.status == 'Payment Failed'">
                                    <i class="fas fa-exclamation-circle"></i>
                                    {{ record.status }}
                                </span>
                                <span class="orderAmountPill font-12 ms-1 mb-0 text-end" v-if="record.total">
                                    {{record.country.currency_symbol}}{{ record.total }}
                                </span>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <p class="font-13 mb-0 font-600">
                                {{ record.items.length }} {{ record.items.length > 1 ? 'Items' : 'Item' }}
                            </p>
                            <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="mt-5 text-center" v-if="!(orders.length > 0)">
            <i class="fas fa-search font-20 text-secondary mb-3"></i>
            <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                No Order Found
            </p>
        </div>
    </div>
    <!------------- PARTNER ORDERS PART END ------------->
</div>

<app-footer />

<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search "
                v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>